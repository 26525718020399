import React from 'react'

const PageNotFound404 = () => {
  return (
    <>
      <h1>404 Page not found</h1>
      <p>The page you are looking for does not exist.</p>
    </>
  )
}

export default PageNotFound404;